var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"home-section"},[(_vm.viewPort('sm'))?_c('div',{staticClass:"card-grid-container"},_vm._l((_vm.cards),function(ref){
var color = ref.color;
var name = ref.name;
var category = ref.category;
return _c('div',{key:name,staticClass:"card-container mt-lg-0 cursor-pointer",on:{"click":function($event){return _vm.to(category)}}},[_c('div',{staticClass:"card-content position-relative overflow-hidden",style:({backgroundColor: color})},[_c('h4',{staticClass:"size-34 weight-600 color-white text-center"},[_vm._v(_vm._s(_vm.$t(("home." + name))))]),_c('div',{staticClass:"bg-circle position-absolute",class:("bg-circle-" + name)}),(name === 'cables')?_c('div',{staticClass:"bg-circle bg-circle-cables-2 position-absolute"}):_vm._e(),_c('img',{staticClass:"position-relative",class:name,staticStyle:{"z-index":"1"},attrs:{"alt":"Categorias","loading":"lazy","src":require(("./" + name + ".webp"))}})])])}),0):_c('div',_vm._l((_vm.cards),function(ref){
var name = ref.name;
var top = ref.top;
return _c('div',{key:name},[_c(name,{tag:"component",style:({marginTop: top})})],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }