var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"home-section row mx-0"},[_c('div',{staticClass:"product-slide-navigation mb-0 mb-md-4 d-flex col-12 px-0 justify-content-between align-items-center"},[_c('main-nav-tab',{staticClass:"products-navigation mb-4 mb-sm-0 col-12 col-md-10",staticStyle:{"justify-content":"start !important","max-width":"860px"},attrs:{"options":_vm.optionsTab,"selectedOption":_vm.selectedOption},on:{"select":function (data) {_vm.changeOption(data)}}}),_c('div',{staticClass:"product-slider-controllers d-none d-md-flex px-lg-0"},_vm._l((_vm.optionsTab),function(ref,index){
var value = ref.value;
var navigation = ref.navigation;
return _c('div',{key:index,staticClass:"swiper-controls",class:{'d-none': value !== _vm.selectedOption.value}},[_c('div',{staticClass:"swiper-button-prev-product-slider d-inline",class:[navigation.prevEl],staticStyle:{"margin-right":"15px"},attrs:{"slot":"button-prev"},slot:"button-prev"},[_c('icon-arrow-circle',{staticClass:"fill-gray-50"})],1),_c('div',{staticClass:"swiper-button-next-product-slider d-inline",class:[navigation.nextEl],attrs:{"slot":"button-next"},slot:"button-next"},[_c('icon-arrow-circle',{staticClass:"fill-gray-50",staticStyle:{"transform":"rotate(180deg)"}})],1)])}),0)],1),_c('div',{staticClass:"px-0"},[_c('div',{staticClass:"product-swipers position-relative"},[_c('div',{class:[_vm.selectedOption.value+'-translate']},_vm._l((_vm.swiperInstances),function(ref){
var name = ref.name;
var products = ref.products;
var options = ref.options;
return _c('div',{key:name,staticClass:"swiper-translate-content"},[_c('swiper',{ref:"productSwiper",refInFor:true,staticClass:"swiper",attrs:{"id":name,"options":options,"pagination":{ clickable: true }}},[_vm._l((products),function(product){return _c('swiper-slide',{key:product.codarticulo},[_c('div',{staticClass:"card-father-container d-flex",on:{"mouseover":function($event){return _vm.pauseSwiper(name)}}},[_c('card-product',{staticClass:"slider-card-product",attrs:{"product":product,"skeleton":_vm.loading,"offer":product.special_price > 0},on:{"showQuickView":_vm.showQuickView,"showShare":_vm.showShare}})],1)])}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"})],2)],1)}),0)])]),(_vm.quickView.value)?_c('quick-view',{attrs:{"product":_vm.quickView.product},on:{"closeModal":function($event){_vm.quickView.value = false}}}):_vm._e(),(_vm.share)?_c('share',{attrs:{"product":_vm.shareProduct},on:{"closeModal":function($event){_vm.share = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }